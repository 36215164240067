<template>
  <div class="detail-container">
    <!-- 1. 데이터 없음 표시 영역 -->
    <div class="detail-placeholder" v-if="!detailInfo">
      <span class="detail-notification-icon">
        <i class="fas fa-external-link-alt"></i>
      </span>
      <span class="detail-notification-text"
        >계측기 유형 코드를 선택하세요.</span
      >
    </div>
    <!-- 2.상세 정보 영역 -->
    <div style="height: calc(100% - 70px)" v-if="detailInfo">
      <!-- 2.1 상세 정보 헤더 영역 -->
      <div class="detail-header" v-if="detailInfo">
        <div class="detail-title">
          <span class="title">{{ viewTitle }}</span>
        </div>
        <div class="detail-control" v-if="!isNewMode">
          <b-form-checkbox switch v-model="isEditMode" size="lg" />
        </div>
      </div>

      <!-- 2.2 상세 정보 컨텐츠 표시 영역 -->
      <div class="detail-content" v-if="detailInfo">
        <BaseInfoPanel :detailInfo="detailInfo" :mode="mode" />
        <!-- {{ detailInfo }} -->
      </div>
    </div>

    <!-- 3.하단 영역 -->
    <div class="detail-bottom">
      <!-- 3.2 하단 제어 버튼 -->
      <div class="detail-bottom-buttons">
        <button
          v-if="isEditMode && !isNewMode"
          type="button"
          class="btn btn-primary detail-btn"
          @click="updateData"
        >
          저장
        </button>
        <button
          v-if="isNewMode"
          type="button"
          class="btn btn-primary detail-btn"
          @click="insertData"
        >
          저장
        </button>
        <button
          v-if="detailInfo"
          type="button"
          class="btn btn-secondary detail-btn"
          @click="handleClose($event)"
        >
          닫기
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInfoPanel from "./panels/BaseInfoPanel.vue";
import backEndApi from "@src/api/backEndApi";

export default {
  props: ["title"],
  components: {
    BaseInfoPanel,
  },
  data() {
    return {
      detailInfo: null,
      mode: "view",
    };
  },
  watch: {},
  computed: {
    isEditMode: {
      get() {
        return this.mode == "edit" || this.mode == "new";
      },
      set(value) {
        this.mode = value ? "edit" : "view";
      },
    },
    isNewMode() {
      return this.mode === "new";
    },
    isViewMode() {
      return this.mode === "view";
    },
    viewTitle() {
      return this.title;
    },
  },
  created() {},
  mounted() {},
  methods: {
    async notifyCancelEdit() {
      if (this.isEditMode) {
        const result = await this.alertConfirmWarning(
          "편집중인 내용을 무시하고 종료할까요?"
        );
        if (result.value) return true;
        else return false;
      }
      return true;
    },
    initData() {
      this.mode = "new";
      this.detailInfo = {
        meterTypeCode: null,
        meterTypeCodeName: null,
        meterTypemDetailDsc: null,
        iconFilePath: null,
        enabled: "Y",
      };
    },
    async loadData(meterTypeCode) {
      try {
        let result = await backEndApi.meterType.getMeterType(meterTypeCode);
        if (result.status == 200) {
          this.detailInfo = result.data;
        } else {
          this.alertWarning(result.data.message);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async handleClose(event) {
      const result = await this.notifyCancelEdit();
      if (!result) {
        event && event.preventDefault();
        return false;
      }
      this.mode = "view";
      this.detailInfo = null;

      return true;
    },
    async updateData() {
      try {
        let result = await backEndApi.meterType.updateMeterType(
          this.detailInfo
        );
        if (result.status == 200) {
          await this.alertNoti("저장하였습니다.");
          await this.$emit("data-updated");
          await this.clearData();
        } else await this.alertWarning(`${result.data.message}`);
      } catch (e) {
        this.alertNoti("데이터를 저장할 수 없습니다.");
        console.error(e);
      }
    },
    async insertData() {
      try {
        let result = await backEndApi.meterType.insertMeterType(
          this.detailInfo
        );
        if (result.status == 200) {
          await this.alertNoti("저장하였습니다.");
          await this.$emit("data-updated");
          await this.clearData();
        } else await this.alertWarning(`${result.data.message}`);
      } catch (e) {
        this.alertNoti("데이터를 저장할 수 없습니다.");
        console.error(e);
      }
    },
    clearData() {
      this.detailInfo = null;
      this.mode = "view";
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
/* detail area styles */
.detail-placeholder {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detail-notification-icon {
  padding: 1rem;
  color: #444;
  font-size: 48px;
}

.detail-notification-text {
  font-size: 15px;
  font-weight: bold;
}

.detail-container {
  height: 100%;
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 10px;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  font-size: 12px;
}

.detail-header {
  margin-bottom: 0px !important;
  padding: 8px 16px 8px 16px;
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 6px 6px 0px 0px;
  background-color: #f1f1f1;
  border: solid #ececec 1px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
}

.detail-header .detail-title {
  padding: 0 !important;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.title {
  font-weight: 600;
  margin-right: 20px;
}

.detail-content {
  height: Calc(100% - 50px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 2px;
  padding: 20px;
  overflow: hidden;
  background-color: white;
  border: solid #ececec 1px;
  border-radius: 0px 0px 6px 6px;
}

.detail-control {
  margin-bottom: 8px !important;
  display: flex;
  justify-content: flex-end;
}

.detail-bottom {
  margin-top: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40 px;
}

.detail-bottom-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 4px;
}

.detail-btn {
  /* height: 50px; */
  flex: 1;
  margin: 0px 4px 0px 4px;
}
</style>